.about-logo-container {
	display: flex;
	justify-content: left;
	padding-top: 25px;
}

.about-logo {
	display: flex;
	position: fixed;
	border: 1px solid white;
	border-radius: 50%;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
	top: 4vh;
}


/* new styles */

.cornell-tech-logo {
    max-width: 50px;  /* Adjust as per your preference */
	max-height: 50px;
    display: block;
    /* margin: 0 auto 20px;  centers the logo and adds margin below */
	margin-right: 10px;
}

.courses-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px; /* spacing between cards */
	width: 90%;
	margin-top: 20px;
	margin-bottom: 20px;
}

.course-card {
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 5px;
    transition: box-shadow 0.3s ease;
}

.course-card:hover {
    box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
}

.course-title {
    font-size: 18px;
    font-weight: bold;
}

.prof-info {
    /* margin-top: 6px; */
	margin-top: -6px;
    display: flex;
    align-items: center;
	font-size: 16px;
}

.prof-info i {
    margin-right: 5px;
}

.course-description {
    margin-top: 10px;
    font-style: italic;
	font-size: 14px;
}


.research-section {
    margin-top: 20px;
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 5px;
	background: linear-gradient(to bottom, #F7F7F7, #FFFFFF);
}

.research-title {
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
}

.sub-research-title {
    font-size: 20px;
    font-weight: bold;
    margin-top: 15px;
}

.thesis-title {
    font-size: 14px;
    margin-top: 10px;
	font-style: italic;
}

.thesis-abstract, .research-assistant-description {
    margin-top: 10px;
	font-size: 12px;
    /* font-style: italic; */
}

.read-more {
	margin-top: 10px;
	text-align: center;
	font-size: 14px;
}

/* new styles end */


.about-container {
	display: flex;
	flex-direction: column;
	height: 100%;
	margin: 0;
	padding-top: 120px;
}

.about-main {
	display: flex;
}

.about-left-side {
	flex-basis: 900px;
}

.about-title {
	width: 100% !important;
}

.about-subtitle {
	display: flex;
	flex-direction: row;
	width: 80% !important;
}

.about-right-side {
	display: flex;
	flex-direction: column;
	flex-basis: 300px;
}

.about-image-container {
	width: 370px;
}

.about-image-wrapper {
	display: inline-block;
	overflow: hidden;
	width: 100%;
	border-radius: 10%;
	transform: rotate(3deg);
}

.about-image-wrapper img {
	display: block;
	width: 100%;
}

.about-socials {
	padding-top: 50px;
	padding-bottom: 50px;
}

.about-socials-mobile {
	display: none;
}

@media (max-width: 1024px) {
	.about-logo {
		z-index: 100;
	}

	.about-main {
		flex-direction: column;
	}

	.about-subtitle {
		flex-direction: row;
		padding-top: 20px !important;
	}

	.about-right-side {
		flex-basis: 100%;
		order: 1;
	}

	.about-left-side {
		flex-basis: 100%;
		order: 2;
	}

	.about-image-container {
		padding-bottom: 40px;
	}

	.about-socials {
		display: none;
	}

	.about-socials-mobile {
		padding-top: 30px;
		display: flex;
	}
}

@media (max-width: 600px) {
	.about-container {
		padding-top: 60px;
	}

	.about-image-container {
		width: 80%;
	}
}
