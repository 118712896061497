@import "../../../data/styles.css";

.project {
	mix-blend-mode: normal;
	border-radius: 20px;
	opacity: 0.8;
	height: 100%;
	border: 1px solid #ddd;
    border-radius: 5px;
    margin-bottom: 20px;
    transition: box-shadow 0.3s ease;
}

.project a {
	text-decoration: none;
}

.project:hover {
	background: #fafafa;
	opacity: 1;
	transition: background-color 0.3s ease-in-out;
	box-shadow: 0px 0px 10px rgba(0,0,0,0.1);

}

.project:hover .project-link {
	color: var(--link-color);
	transition: color 0.3s ease-in-out;
}

.project-container {
	padding: 30px;

}

.project-logo {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	/* wrap */
	flex-wrap: wrap;
	/* add vertical distance if wrapping */
	align-content: space-between;
}

.project-logo img {
	max-height: 30px;
	padding-right: 25px;
	padding-bottom: 5px;
}

.project-title {
	font-family: var(--secondary-font);
	color: var(--primary-color);
	font-size: 16px;
	padding-top: 15px;
	margin-bottom: 1em;
	font-weight: 700;
}

.project-description {
	color: var(--secondary-color);
	font-size: small;
}

.project-link {
	display: flex;
	align-items: center;
	color: var(--secondary-color);
	font-size: 12px;
	padding-top: 20px;
}

.project-link-icon {
	padding-left: 5px;
	font-size: 13px;
}

.project-status {
	display: flex;
	align-items: center;
	color: var(--link-color);
	font-size: smaller;
	font-style: italic;
	padding-top: 20px;
}

.project-link-text {
	padding-left: 10px;
	font-weight: 700;
}

@media (max-width: 800px) {
	.project {
		height: 300px;
	}
}
