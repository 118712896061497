.articles-logo-container {
	display: flex;
	justify-content: left;
	padding-top: 25px;
}

.articles-logo {
	display: flex;
	position: fixed;
	border: 1px solid white;
	border-radius: 50%;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
	top: 4vh;
}

.articles-main-container {
	display: flex;
	flex-direction: column;
	height: 100%;
	margin: 0;
	padding-top: 120px;
}


/* new styles: */

.experience-container {
    margin-top: 20px;
	width: 90%;
}

.experience-card {
    padding-left: 15px;
	padding-right: 15px;
	padding-top: 5px;
	padding-bottom: 15px;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin-bottom: 20px;
    transition: box-shadow 0.3s ease;
}

.experience-card:hover {
    box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
}
.experience-header {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.company-logo {
    width: 35px;
    margin-right: 15px;
    object-fit: contain;
}

.experience-details {
    flex: 1;  /* to make sure it takes the remaining width */
}

.experience-top-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
	margin-bottom: -10px;
}

.experience-second-row{
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.job-title {
    font-size: 18px;
}

.company-name {
    color: #007ACC; 
    text-decoration: none;
    font-weight: bold;
    flex: 1;  /* gives the company name more space in case it's long */
}

.job-date {
    font-size: 14px;
    color: gray;
    white-space: nowrap;  /* ensures date stays in one line */
}


.company-name {
    color: #007ACC; /* Example link color, adjust as needed */
    text-decoration: none;
    font-weight: bold;
}

.company-name:hover {
    text-decoration: underline;
}


.job-description {
    margin-top: 10px;
	font-size: 14px;
}

.skills {
    margin-top: 10px;
	font-size: 14px;
    font-style: italic;	
}

/* new styles end */


.articles-container {
	display: flex;
	flex-direction: column;
}

.articles-wrapper {
	padding-top: 50px;
	padding-bottom: 50px;
	display: flex;
	flex-direction: column;
}

.articles-article {
	padding-top: 0px;
	padding-left: 35px;
	padding-bottom: 20px;
	border-left: 2px solid #f4f4f5;
	width: 100%;
}

@media (max-width: 1024px) {
	.articles-title {
		width: 100% !important;
	}

	.articles-subtitle {
		width: 100% !important;
	}
}
