@import "../../../data/styles.css";
.social,
.email-wrapper {
	display: flex;
	padding-bottom: 10px;
	padding-right: 30px;
}


.inside-link{
	/* center the content */
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;	
	min-width: 100px;
}

.social a,
.email-wrapper a {
	text-decoration: none;
}

.social-icon {
	font-size: 15px;	
	color: var(--secondary-color);
}

.social-text {
	color: var(--secondary-color);
	padding-left: 12px;
	/* margin-top: -20px; */
	font-weight: 600;
	font-size: 14px;
}

.email::before {
	/* content: ""; */
	/* display: block; */
	/* padding-top: 20px; */
	/* border-top: 3px solid #f4f4f5; */
	/* margin-top: 20px; */
}

.email-wrapper {
	/* padding-top: 20px; */
}
